import { ApplicationConfig } from "projects/_shared/models/configuration/application.config";
import { Brand, Country } from "projects/_shared/models/enums";
import { StorageType } from 'customerdigital-service-lib'
import { Market } from 'projects/_shared/shared/enum';

export const applicationConfig: ApplicationConfig = {
  BRAND: Brand.BMW,
  COUNTRY: Country.CANADA,
  CLIENT_ID: 'C0631A08-54F4-42CC-8C70-0A2404758B3C',
  SITEID: 1,
  LANGUAGE: 'EN',
  REFERSH_BEARER_TOKEN_INTERVAL_IN_SEC: 3300,
  MARKET: "CA",
  COUNTRYCODE: 'CA',
  SCOPE: "authenticate_user",
  RESPONSE_TYPE: "code",
  GRANT_TYPE: "authorization_code",
  STORAGE_TYPE: StorageType.session,
  CLIENT: "canhub",
  POLICY_FORMAT: 'HTML',
  POLICY_ID: 'BMW_AUTHHUB_TAC',
  FAQ_PRESENTATION_TYPE: 0,
  CONTACT_US: "",
  TITLE: "MyProfile-BMW-CAN",
  REGION: Market.Canada,
  LOADER_IMG_SRC: "assets/images/loading-icon.gif",
  EPAAS_TENANT_ID: "profile_bmw_ca",
  EPAAS_FEATURE_TYPE: "ePaaS",
  TENANT: "CA",
  PRODUCT: "MyProfile",
  FOOTER_MENU_ITEM: ""
}