<header>
  <nav aria-label="header">
    <div class="nav-secondary">
      <div class="desktop-container">
        <span *ngIf="!isLoggedIn" class="header-dropdown-notloggedin" (click)="ssoLogout()">{{ 'top-navigation.dropdown.header' | translate }}</span>
        <ul *ngIf="isLoggedIn" class="nav">
          <li class="btn-account-dropdown">
            <button id="MenuBtn" mat-button [matMenuTriggerFor]="menu" [disableRipple]="true"
              (menuOpened)="toggleMenu()" (menuClosed)="toggleMenu()" (click)="scrollToTop()"
              [ngClass]="{'menu-open': menuOpened}">
              <fs-icon name="menuIcon" alt="logo">
              </fs-icon>
              <span>{{ 'top-navigation.dropdown.header' | translate }}</span>
            </button>
            <mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before" [hasBackdrop]="false">
              <button [attr.id]="item.eventName +'MenuItemLnk'" *ngFor="let item of headerMenuItem"
                (click)="itemSelect(item)" mat-menu-item>
                <fs-icon [name]="item.iconType!" alt="{{item.text}}">
                </fs-icon>
                {{ item.text }}
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="desktop-header-container">  
    <div class="sub-nav-header">
      <div class="brand-logo" (click)="logoClick()"><img src='{{ "global.login-bg-src" | translate }}' class="brand-image" alt="{{'terms.view.brand-alt' | translate}}"></div>
      <div class="page-title"> {{'top-navigation.header' | translate}}</div>
    </div>
  </div>
</header>