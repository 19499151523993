import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HeaderMenuItem } from 'projects/_shared/models/header.model';
import { RouterService } from 'projects/_shared/shared/helper-service/router.service';
import { WindowHelperService } from 'projects/_shared/shared/helper-service/window-helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  /** Used to indicate if user logged-in and display menuitem or not. */
  @Input() isLoggedIn!: boolean;
  /** Used to set menu items as an array of HeaderMenuItem for Header Component. */
  @Input() headerMenuItem!: Array<HeaderMenuItem>; 

  /** Event emitted when menu item is selected */
  @Output() onMenuItemSelect: EventEmitter<any> = new EventEmitter<any>();
  /** Event emitted when logo icon clicked */  
  @Output() logoLinkClick: EventEmitter<any> = new EventEmitter<any>();

  public menuOpened!: boolean;  

  constructor(private windowHelperService: WindowHelperService,
    private routerService: RouterService) {
  }

  ngOnInit(): void {  
  }

  logoClick(){
    this.logoLinkClick.emit();
  }

  itemSelect(item: HeaderMenuItem) {
    if(item)
      this.onMenuItemSelect.emit(item.eventName);
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }
  
  scrollToTop() {
    this.windowHelperService.scrollToTop();
  }

  ssoLogout(){
    this.routerService.navigateToSSOLogout();
  }

}
