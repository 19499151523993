import { Component,OnDestroy,OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SpinnerHelperService } from 'projects/_shared/shared/helper-service/spinner.service';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { IndicatorActions } from 'projects/_shared/shared/store/reducers/indicator.reducer';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../_shared/web-service/auth.service';
import { StringModel } from 'projects/_shared/models/string.model';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-terms-view',
  templateUrl: "./terms-view.component.html",
  styleUrls: ['./terms-view.component.scss']
})

export class TermsViewComponent implements OnInit, OnDestroy {
  termsLoadFailed= false;
  htmlContent !: SafeHtml;
  langChangeSubscription: any;
  dataLoadedSubscription: Subscription;
  dataLoaded!: boolean;


  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private transService: TranslateService,
    private spinnerService: SpinnerHelperService,
    private store: Store<IAppState>
  ) {
    this.dataLoadedSubscription = this.store.select(state => state.DataLoaded)
      .subscribe(loaded => this.dataLoaded = loaded!);
    this.langChangeSubscription = this.store.select(state => state.Language?.stringValue).subscribe(() => {
        this.getTermsHtml();
      }
    );
  }

  ngOnInit() {    
    this.spinnerService.setDataLoaded(false);
    
  }
  
  
  
  private getTermsHtml(){
    this.authService.getTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });

  }

  closeTerms(){
    window.close();
  }
  displayTermsCondition(success: boolean, terms: string) {
    if (success) {
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(terms);
    }
    this.termsLoadFailed = !success;
    this.spinnerService.setDataLoaded(true);
  }
  
  ngOnDestroy(): void {
    this.dataLoadedSubscription.unsubscribe();
    this.langChangeSubscription.unsubscribe();
  }
}
