<app-epaas-error></app-epaas-error>
<app-header [isLoggedIn]="isAuthenticated" [headerMenuItem]="headerMenuItems"
  (onMenuItemSelect)="onMenuItemSelect($event)" (logoLinkClick)="logoLinkClick()" class="no-print">
</app-header>
<div class="main-container">
  <router-outlet></router-outlet>
  <app-spinner *ngIf="!dataloaded"></app-spinner>
</div>

<div class="bmw-footer" *ngIf="dataloaded">
  <fs-auth-hub-footer [copyRightText]="copyRightText" [footerMenuItems]="footerMenuItems"
    (onClick)="onFooterMenuItemSelect($event)" [selectedIndex]="selectedIndex">
  </fs-auth-hub-footer>

</div>