import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header.component';
import { CommonModule } from '@angular/common';
import { FsIconModule } from 'customerdigital-ui-components-lib'
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    TranslateModule,
    FsIconModule,
    CommonModule,
    MatMenuModule,
    MatButtonModule
  ],
  declarations: [
    HeaderComponent
  ],
  providers: [],
  exports: [HeaderComponent]
})
export class HeaderModule { }
