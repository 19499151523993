import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { route } from 'projects/_shared/shared/helper-service/router.service';
import { SsoLogoutComponent, SsoComponent } from 'customerdigital-service-lib';
import { TermsAcceptedGuard } from 'projects/_shared/_guards/terms-accepted.guard';
import { HomeResolve } from 'projects/_shared/components/home/home.resolve';
import { AuthGuard } from 'projects/_shared/_guards/auth.guard';

const routes: Routes = [
  { path: "", pathMatch: 'full', redirectTo: route.home },
  {
    path: route.home, loadChildren: () => import('../../../_shared/components/home/home.module').then(m => m.HomeModule),
    canActivate: [TermsAcceptedGuard, AuthGuard],
    resolve: {
      void: HomeResolve
    }
  },
  {
    path: route.sso, component: SsoComponent
  },
  {
    path: route.faq, loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
  },
  {
     path: route.cookiePolicy, loadChildren: () => import('../../../_shared/feature/cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule) 
  },
  {
    path: route.ssoLogout, component: SsoLogoutComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
